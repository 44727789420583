import React, { useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import cx from 'classnames';
import { SpacingProps, useSpacing } from 'src/hooks/spacing';
import { ReactComponent as DownArrorSVG } from 'src/images/icons/24px/down-arrow.svg';

import { TextInput } from '@clubspark-react/clubspark-react-tools';

import * as styles from './mui-select.module.less';

export type Option = {
  label: string;
  value: string;
  options?: Option[];
};

interface Props extends SpacingProps, SelectProps {
  options: Option[];
  onSelect?: (option: Option) => void;
  defaultValue?: string;
  placeholder?: string;
  props?: any;
}

const MUISelect: React.FC<Props> = ({
  options,
  onSelect,
  placeholder,
  defaultValue = '',
  spacing,
  ...props
}: Props) => {
  const spacingClass = useSpacing(spacing);
  const [selected, setSelected] = useState(defaultValue);
  const [open, setOpen] = useState(false);

  const handleChange = (e: SelectChangeEvent<{ value: unknown }>) => {
    const option = options.find((o) => o.value === e.target.value);
    option && onSelect && onSelect(option);
    setSelected(option.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Select
      open={open}
      value={selected}
      onClose={handleClose}
      onOpen={handleOpen}
      onChange={handleChange}
      variant="outlined"
      fullWidth
      className={cx(styles.select, spacingClass)}
      classes={{
        outlined: styles.rootOverride,
        select: selected === 'none' ? styles.placeholder : styles.selectOverride,
        disabled: styles.disabled,
      }}
      input={<TextInput outlined disableUnderline fullWidth />}
      IconComponent={DownArrorSVG}
      MenuProps={{ classes: { list: styles.list } }}
      {...props}
    >
      {!!placeholder && (
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
      )}
      {options.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MUISelect;
