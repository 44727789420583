import { default as React, useCallback, useEffect, useMemo, useState } from 'react';

import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { FaMinus, FaPlus } from 'react-icons/fa';
import ExpansionListPagination from 'src/components/expansion-list-pagination/expansion-list-pagination';
import { ControlProps } from 'src/components/table-controls/table-controls';
import { BodyLarge } from 'src/components/typography/typography';
import { SpacingProps } from 'src/hooks/spacing';
import { retrieveRowsPerPage, storeRowsPerPage } from 'src/utils/storage/local-storage';

import { Button, Spinner } from '@clubspark-react/clubspark-react-tools';

import * as styles from './expansion-list.module.less';

export interface Datum {
  id: string;
}

export type DataCols<T extends Datum> = {
  key: string;
  title?: string;
  getValue: (t: T) => React.ReactNode;
}[];

interface Props<T extends Datum> extends SpacingProps {
  columns: DataCols<T>;
  data: T[];
  title?: string;
  controls?: ControlProps;
  loading?: boolean;
  actions?: boolean;
  headers?: DataCols<T>;
  handleClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, t: T) => void;
}

function ExpansionList<T extends Datum>(props: Props<T>) {
  const { columns, data, actions, controls, loading, handleClick, headers } = props;
  const [page, setPage] = useState(0);
  const storedRowsPerPage = useMemo(() => retrieveRowsPerPage(), []);
  const [rowsPerPage, setRowsPerPage] = useState(storedRowsPerPage ?? 10);

  const getValues = columns.map((value) => {
    return value.getValue;
  });

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<object>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangePage = useCallback(
    (_, newPage: number) => {
      setPage(newPage);
    },
    [setPage],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    },
    [setPage, setRowsPerPage],
  );

  useEffect(() => {
    storeRowsPerPage(rowsPerPage);
  }, [rowsPerPage]);

  const { onControlChange } = controls ?? {};

  useEffect(() => {
    onControlChange?.({
      pagination: {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
      },
    });
  }, [page, rowsPerPage, onControlChange]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className={styles.listRoot}>
          {data.map((t) => {
            return (
              <div key={t.id}>
                <Accordion
                  key={t.id}
                  expanded={expanded === t.id}
                  onChange={handleChange(t.id)}
                  className={styles.expansionPanel}
                  classes={{ expanded: styles.rootExpanded }}
                  square
                >
                  <AccordionSummary
                    key={t.id}
                    expandIcon={
                      expanded === t.id ? (
                        <FaMinus className={styles.itemIconMinus} />
                      ) : (
                        <FaPlus className={styles.itemIconPlus} />
                      )
                    }
                    className={styles.panelSummary}
                    classes={{ expandIconWrapper: styles.icon }}
                  >
                    <BodyLarge>{headers ? headers[0].getValue(t) : ''}</BodyLarge>
                  </AccordionSummary>
                  {columns.slice(1, columns.length + 1).map((c, index) => {
                    return (
                      <AccordionDetails key={c.key}>
                        {expanded === t.id && (
                          <ExpansionListItem key={c.key} value={getValues[index + 1](t)} title={c.title ?? c.title} />
                        )}
                      </AccordionDetails>
                    );
                  })}
                  {actions && (
                    <AccordionActions>
                      {handleClick && <Button onClick={(e) => handleClick(e, t)}>Open</Button>}
                    </AccordionActions>
                  )}
                </Accordion>
              </div>
            );
          })}
          <ExpansionListPagination
            count={controls?.totalItems ?? 0}
            onPageChange={handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </>
  );
}

interface ItemProps {
  value: any;
  title?: string;
}

const ExpansionListItem: React.FC<ItemProps> = ({ value, title }: ItemProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={styles.nameLabel}>{title ?? title}</Typography>
      </Grid>
      <Typography component="div" className={styles.valueLabel}>
        {value}
      </Typography>
    </Grid>
  );
};

export default ExpansionList;
