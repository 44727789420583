import React from 'react';

import { TablePagination, TablePaginationProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ROWS_PER_PAGE_OPTIONS } from 'src/components/table-controls/table-controls';
import TablePaginationActions from 'src/components/table-pagination-actions/table-pagination-actions';

import * as styles from './expansion-list-pagination.module.less';

type PaginationProps = Pick<
  TablePaginationProps,
  'count' | 'rowsPerPage' | 'page' | 'onPageChange' | 'onRowsPerPageChange'
> & {
  mobile?: boolean;
};

const ExpansionListPagination: React.FC<PaginationProps> = (props) => {
  const { t } = useTranslation();

  return (
    <TablePagination
      {...props}
      component="div"
      labelDisplayedRows={({ count: total, ...rest }) => t('showing displayed pages', { ...rest, total })}
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      slotProps={{ select: { native: true } }}
      ActionsComponent={TablePaginationActions}
      labelRowsPerPage={props.mobile ? t('per page') : ''}
      classes={{
        toolbar: styles.paginationToolbar,
        spacer: styles.paginationSpacer,
        actions: styles.paginationActions,
        selectLabel: styles.paginationCaption,
        displayedRows: styles.paginationCaption,
        selectRoot: styles.paginationSelect,
      }}
    />
  );
};

export default ExpansionListPagination;
