import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import LTAMembershipDetails from 'src/components/lta-membership-details/lta-membership-details';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const LTAMembershipDetailsPage = () => {
  return (
    <Layout>
      <SEO title="Membership package details" />
      <Router basepath="/lta-memberships">
        <LTAMembershipDetailsRoute path="/:membershipPackageId" />
      </Router>
    </Layout>
  );
};

interface LTAMembershipDetailsRouteProps extends RouteComponentProps {
  membershipPackageId?: string;
}

const LTAMembershipDetailsRoute: React.FC<LTAMembershipDetailsRouteProps> = ({ membershipPackageId }) => {
  return (
    <PrivateRoute>
      <LTAMembershipDetails membershipPackageId={membershipPackageId} />
    </PrivateRoute>
  );
};

export default LTAMembershipDetailsPage;
