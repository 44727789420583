import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { debounce, Grid, Theme, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import { RouterLink } from 'src/components/button/link';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import EmptyState from 'src/components/empty-state/empty-state';
import ExpansionList from 'src/components/expansion-list/expansion-list';
import Select from 'src/components/mui-select/mui-select';
import Panel from 'src/components/panel/panel';
import StatusLabel, { LabelVariety } from 'src/components/status-label/status-label';
import { ControlProps, useControlledQuery } from 'src/components/table-controls/table-controls';
import { PaymentStatus } from 'src/graphql-types/lta-registration/globalTypes';
import { retrieveRowsPerPage } from 'src/utils/storage/local-storage';

import { BodySmall, BodySmallBold, H3, H4, Spinner, TextInput } from '@clubspark-react/clubspark-react-tools';

import {
  GET_MEMBER_BY_NAME,
  GET_MEMBER_BY_PAYMENT_REFERENCE,
  GET_MEMBERS_FOR_PACKAGE,
  GET_MEMBERSHIP_PACKAGE_BY_ID,
} from './lta-membership-details-queries';
import * as styles from './lta-membership-details.module.less';

export interface MembersResponse {
  memberMembershipPackage: {
    memberCount: number;
    memberList: LTAMember[];
  };
}
export interface LTAMember {
  addedDate?: string;
  courtCount: number;
  grassCourtCount: number;
  id: string;
  isNew: boolean;
  membershipPackageID: string;
  name: string;
  packageYear: number;
  paymentMethod: string;
  paymentReference: string;
  paymentStatus: string;
  salesforceID: string;
  status: string;
  totalCost: number;
}

interface LTAMembershipDetailsProps {
  membershipPackageId?: string;
}

const LTAMembershipDetails: React.FC<LTAMembershipDetailsProps> = ({ membershipPackageId }) => {
  const hiddenSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const hiddenOnlyXs = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const rowsPerPage = retrieveRowsPerPage() ?? 10;
  const [filteredData, setFilteredData] = useState<LTAMember[]>();
  const [memberFilter, setMemberFilter] = useState('any');
  const [paymentFilter, setPaymentFilter] = useState('any');
  const { t } = useTranslation();
  const { data, loading } = useQuery(GET_MEMBERSHIP_PACKAGE_BY_ID, {
    variables: {
      id: membershipPackageId,
    },
    fetchPolicy: 'no-cache',
  });

  const [searchMembersByName, { data: searchNameData, loading: searchNameLoading }] = useLazyQuery(GET_MEMBER_BY_NAME);

  const [
    searchMembersByPaymentReference,
    { data: searchPaymentReferenceData, loading: searchPaymentReferenceLoading },
  ] = useLazyQuery(GET_MEMBER_BY_PAYMENT_REFERENCE);

  function transformVariables(optionsWithControls) {
    const { limit = 10, offset = 0 } = optionsWithControls ?? {};

    return {
      id: membershipPackageId,
      skip: offset,
      limit,
      paymentStatusList: [PaymentStatus.PAID, PaymentStatus.PENDING, PaymentStatus.FAILED],
    };
  }

  const getTotalItems = useCallback((d: any) => d.memberMembershipPackage.memberCount, []);

  const getSortProperty = useCallback((key) => {
    return key;
  }, []);

  const {
    data: membersData,
    loading: membersLoading,
    error: membersError,
    controlProps,
    refetch,
  } = useControlledQuery<MembersResponse>(GET_MEMBERS_FOR_PACKAGE, {
    getTotalItems,
    getSortProperty,
    transformVariables,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const controls: ControlProps = { ...controlProps, sortDisabled: true };

  useEffect(() => {
    if (searchNameData) {
      const newData = searchNameData?.searchMembersByName?.memberList as LTAMember[];
      setFilteredData(newData);
    }
  }, [searchNameData]);

  useEffect(() => {
    if (searchPaymentReferenceData) {
      const newData = searchPaymentReferenceData?.searchMembersByPaymentReference?.memberList as LTAMember[];
      setFilteredData(newData);
    }
  }, [searchPaymentReferenceData]);

  const getVariety = (status: string) => {
    switch (status) {
      case 'MEMBER':
        return 'success';
      case 'NONMEMBER':
        return 'error';
      case 'LAPSED':
        return 'neutral2';
      default:
        return 'neutral';
    }
  };

  const getStatusVariety = (status: PaymentStatus) => {
    switch (status) {
      case PaymentStatus.PAID:
        return 'success';
      case PaymentStatus.PENDING:
        return 'warning';
      case PaymentStatus.NOTPAID:
      case PaymentStatus.FAILED:
        return 'error';
      default:
        return 'neutral';
    }
  };

  const getPaymentStatusLabel = (status: PaymentStatus) => {
    switch (status) {
      case PaymentStatus.PAID:
        return 'Paid';
      case PaymentStatus.NOTPAID:
        return 'Not paid';
      case PaymentStatus.PENDING:
        return 'Pending';
      case PaymentStatus.FAILED:
        return 'Failed';
      default:
        return 'Unknown';
    }
  };

  const getMemberStatusLabel = (status: string) => {
    switch (status) {
      case 'MEMBER':
        return 'Active';
      case 'NONMEMBER':
        return 'Expired';
      case 'LAPSED':
        return 'Lapsed';
      default:
        return 'Unknown';
    }
  };

  const cols: DataCols<LTAMember> = useMemo(
    () => [
      { key: 'organisationName', title: 'Organisation', getValue: (m) => m.name },
      {
        key: 'memberStatus',
        title: 'Member status',
        getValue: (m) => <StatusLabel variety={getVariety(m.status)}>{getMemberStatusLabel(m.status)}</StatusLabel>,
      },
      { key: 'amountPaid', title: t('amount'), getValue: (m) => `£${m.totalCost.toFixed(2)}` },
      {
        key: 'paymentReference',
        title: 'Payment reference',
        getValue: (m) => m.paymentReference,
      },
      {
        key: 'paymentStatus',
        title: 'Payment status',
        getValue: (m: { paymentStatus: PaymentStatus }) => (
          <StatusLabel variety={getStatusVariety(m.paymentStatus)}>
            {getPaymentStatusLabel(m.paymentStatus)}
          </StatusLabel>
        ),
      },
    ],
    [t],
  );

  const headers: DataCols<LTAMember> = useMemo(
    () => [{ key: 'title', title: t('organisation'), getValue: (m) => m.name }],
    [t],
  );

  const membershipPackageName = data?.membershipPackage?.name ?? 'Name';
  const membershipPackageStatus = data?.membershipPackage?.status;
  let label = '';
  let variety: LabelVariety = 'neutral';
  if (membershipPackageStatus === 'PUBLIC') {
    variety = 'success';
    label = 'Approved';
  } else if (membershipPackageStatus === 'PRIVATE') {
    variety = 'error';
    label = 'Not approved';
  }

  const handleOnClickTableRow = (t: LTAMember) => {
    navigate(`/lta-memberships/lta-member/${membershipPackageId}/${t.name}/${t.id}`);
  };

  if (loading) {
    return (
      <Grid container>
        <Spinner />
      </Grid>
    );
  }

  const DropdownContainer = ({ children }) => (
    <Grid item className={styles.dropdownContainer}>
      {children}
    </Grid>
  );

  const memberStatusOptions = [
    { label: t('Active'), value: 'member' },
    { label: t('Expired'), value: 'nonmember' },
    { label: t('Lapsed'), value: 'lapsed' },
  ];
  const paymentStatusOptions = [
    { label: t('Paid'), value: 'paid' },
    { label: t('Not paid'), value: 'notpaid' },
  ];

  const handleOrganisationSearch = debounce((value: string) => {
    if (value.length == 0) {
      setFilteredData(undefined);
      refetch();
    }
    if (!searchNameLoading && !searchPaymentReferenceLoading && value?.length >= 3) {
      searchMembersByName({
        variables: {
          name: value,
          membershipPackageID: membershipPackageId,
          limit: rowsPerPage,
          skip: 0,
        },
      });
    }
  }, 400);

  const handlePaymentReferenceSearch = debounce((value: string) => {
    if (value.length == 0) {
      setFilteredData(undefined);
      refetch();
    }

    if (!searchNameLoading && !searchPaymentReferenceLoading && value?.length >= 3) {
      searchMembersByPaymentReference({
        variables: {
          paymentReference: value,
          membershipPackageID: membershipPackageId,
          limit: rowsPerPage,
          skip: 0,
        },
      });
    }
  }, 400);

  const handleMemberFilter = (option) => {
    if (memberFilter !== option.value) {
      setMemberFilter(option.value);
      setPaymentFilter('any');
      setFilteredData(
        option.value === 'any'
          ? membersData?.memberMembershipPackage.memberList
          : membersData?.memberMembershipPackage.memberList.filter(
              (item) => item.status === option?.value.toUpperCase(),
            ),
      );
    }
  };

  const handlePaymentFilter = (option) => {
    if (paymentFilter !== option.value) {
      setPaymentFilter(option.value);
      setMemberFilter('any');
      setFilteredData(
        option.value === 'any'
          ? membersData?.memberMembershipPackage.memberList
          : membersData?.memberMembershipPackage.memberList.filter(
              (item) => item.paymentStatus === option?.value.toUpperCase(),
            ),
      );
    }
  };

  return (
    <>
      <Breadcrumbs
        paths={[
          { name: 'Memberships', to: '/lta-memberships' },
          { name: [membershipPackageName], to: '' },
        ]}
      />
      <Panel>
        <Grid container justifyContent="space-between">
          <Grid container item xs>
            <H3>{membershipPackageName}</H3>
          </Grid>
          {!!membershipPackageId && (
            <Grid container item xs justifyContent="flex-end">
              <RouterLink size="md" type="button" level="tertiary" to={`/lta-memberships/${membershipPackageId}/edit`}>
                {t('edit membership')}
              </RouterLink>
            </Grid>
          )}
        </Grid>
        <div className={styles.divider} />
        <Grid container direction="row" alignItems="center">
          <Grid item xs={6} sm={6} md={1} lg={1}>
            <BodySmallBold>{t('code')}</BodySmallBold>
            <BodySmall classnames={styles.subHeading}>
              {data?.membershipPackage?.code?.toUpperCase() ?? 'Code'}
            </BodySmall>
          </Grid>
          <Grid item xs={6} sm={6} md={1} lg={1}>
            <BodySmallBold>{t('type')}</BodySmallBold>
            <BodySmall classnames={styles.subHeading}>{data?.membershipPackage?.type}</BodySmall>
          </Grid>
          <Grid item xs={6} sm={6} md={1} lg={1}>
            <BodySmallBold>{t('status')}</BodySmallBold>
            <BodySmall classnames={styles.subHeading}>
              <StatusLabel variety={variety}>{label}</StatusLabel>
            </BodySmall>
          </Grid>
          <Grid item xs={6} sm={6} md={1} lg={1}>
            <BodySmallBold>{t('start date')}</BodySmallBold>
            <BodySmall classnames={styles.subHeading}>
              {dayjs.utc(data?.membershipPackage?.startDate).format('DD/MM/YYYY')}
            </BodySmall>
          </Grid>
          <Grid item xs={6} sm={6} md={1} lg={1}>
            <BodySmallBold>{t('end date')}</BodySmallBold>
            <BodySmall classnames={styles.subHeading}>
              {dayjs.utc(data?.membershipPackage?.renewsOn).subtract(1, 'days').format('DD/MM/YYYY')}
            </BodySmall>
          </Grid>
        </Grid>
      </Panel>
      <Panel>
        <>
          <Grid container alignItems="center">
            <H4 spacing={{ margins: { xs: 'bottom' } }}>Members</H4>
          </Grid>
          <Grid container>
            <CustomGrid className={styles.searchBox}>
              <TextInput
                placeholder={t('Organisation name')}
                outlined
                disableUnderline
                onChange={(e) => handleOrganisationSearch(e.target.value)}
              />
            </CustomGrid>
            <CustomGrid className={styles.searchBox}>
              <TextInput
                placeholder={t('Payment reference')}
                outlined
                disableUnderline
                onChange={(e) => handlePaymentReferenceSearch(e.target.value)}
              />
            </CustomGrid>
            <DropdownContainer>
              <Select
                options={[{ label: t('Member status'), value: 'any' }, ...memberStatusOptions]}
                onSelect={handleMemberFilter}
                defaultValue={memberFilter}
              />
            </DropdownContainer>
            <DropdownContainer>
              <Select
                options={[{ label: t('Payment status'), value: 'any' }, ...paymentStatusOptions]}
                onSelect={handlePaymentFilter}
                defaultValue={paymentFilter}
              />
            </DropdownContainer>
          </Grid>

          {membersData?.memberMembershipPackage?.memberList?.length === 0 || membersError ? (
            <EmptyState title="There are no organisations for this membership package" icon="lg-empty-state" />
          ) : (
            <>
              {hiddenOnlyXs ? null : (
                <AdminTable
                  columns={cols}
                  data={filteredData || (membersData?.memberMembershipPackage?.memberList ?? [])}
                  onRowClick={handleOnClickTableRow}
                  loading={loading || membersLoading || searchNameLoading}
                  // error={error}
                  controls={controls}
                />
              )}
              {hiddenSmUp ? null : (
                <ExpansionList
                  columns={cols}
                  data={filteredData || (membersData?.memberMembershipPackage?.memberList ?? [])}
                  controls={controlProps}
                  loading={loading || membersLoading || searchNameLoading}
                  handleClick={() => {}}
                  headers={headers}
                  actions
                />
              )}
            </>
          )}
        </>
      </Panel>
    </>
  );
};

export default LTAMembershipDetails;
